import * as React from "react"
import Layout from '../components/layout'
import {StaticImage} from "gatsby-plugin-image";
import '../styles/index.scss';

const IndexPage = () => {
  return (
    <Layout>
        <section className="cover-img wrapper">
            <h1 className='main-title'>"Experience the technology in World of Valet Parking"</h1>
            <span>We are enhanced with our extensive expertise, empowering organizations with
              a one-stop solution for your parking requirements.
            </span>
        </section>
        
        <div className="wrapper">
            <h1 className='heading'>
                <StaticImage className="section-img" src='../images/technology.png' alt=""/>
                Why Us ?</h1>
            <p>Our aim is to provide a sustainable, high quality and friendly service to all of our customers across the world.</p>
            <p>Experience the agility, scalability, availability, and performance benefits that valet parking has to offer. We are
                committed towards our job and services.</p>
        </div>
        <div className="container-wrapper">
            <h1 className='heading'>
                <StaticImage className="section-img" src='../images/idea.png' alt=""/>What we have for you?</h1>
            <p>We have our products with amazing features, which solves your problems related to your parking.</p>
            <div className='product-wrapper'>
                <div className='product-container'>
                    <div className='product-name'>Essential+</div>
                    <div className='product-features'>
                        <p>
                            <ul>
                                <li> iOS and Android App ($0) Include in Smart Valet Ticket charges. The app comes with a
                                    standard parking app for all employees, managers, and business owners.</li>
                                <li> App access via single or multiple employee-owned devices.</li>
                                <li> Request to Bring Car alert and alert reminder via App Push Notification and Email.</li>
                                <li> Guest vehicle status and alert via push notification, no need to share any personal
                                    information, and no need to download any app.</li>
                                <li> Staff Management - Role & Permission and PIN.</li>
                                <li> 99.9% SLA, Bank-grade security, GDPR compliant, Email support</li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div className='product-container'>
                    <div className='product-name'>Express+</div>
                    <div className='product-features'>
                        <p>
                            <ul>
                                <li> All Essential+ features</li>
                                <li> Request to Bring Car alerts via cell text message to any one cell number (Additional cell number $15 per cell per 1000 tickets)</li>
                                <li> Missing Key Alert</li>
                                <li> ALPR - Automatic License Plate Recognition including Make, Model, and Color.</li>
                                <li> DD Zone Pink Card (All in one QR) - Membership/Loyalty card support.</li>
                                <li> Back-to-Back or Multi-level parking support.</li>
                                <li> Email and chat support.</li>
                                <li> Fully ticketless valet solution</li>
                            </ul>
                        </p>
                    </div>
                </div>

                <div className='product-container'>
                    <div className='product-name'>Professional+</div>
                    <div className='product-features'>
                        <p>
                            <ul>
                                <li> All Express+ features</li>
                                <li> Online and Offline Payment at the cashier or consumer side with tip and gratuity.</li>
                                <li> Online Ordering System - parking reservation for the end-consumer.</li>
                                <li> Timesheet (Clock-In/Clock-Out) and Shift planner/management.</li>
                                <li> Analytics, Garage Report, Revenue Report, Employee Online Status, and Timesheet.</li>
                                <li> Oracle Opera PMS Integration. </li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
  )
}

export default IndexPage
